import {Component, OnInit, ViewChild} from '@angular/core';
import {EverythingService} from '../everything.service';
import {HttpClient} from '@angular/common/http';
import {animate, animateChild, query, stagger, state, style, transition, trigger, group} from '@angular/animations';
import {PerfectScrollbarComponent} from 'ngx-perfect-scrollbar';
import {Subject, timer} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {SoundService} from '../sound.service';
import {slideLeft} from '../_animations/slide-left';
import {containerSlideUp} from '../_animations/slide-up';
import {QuestionService} from '../services/question.service';
import {QuizService} from '../services/quiz.service';
import {Quiz} from '../models/quiz';
import {pageTransition} from '../_animations/page-transition';
import {TrackService} from '../services/track.service';


declare let gtag: Function;

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  //host: {'[@pageTransition]': ''},
  animations: [
    trigger('slideInNew', [

      transition(':enter', [
          group([
            style({position: 'absolute', left: 0, right: 0, opacity: 0}),
            animate('.5s ease-in-out',
            style({opacity: 1})),
            query('.answer', [
              style({opacity: 0, transform: 'translateY(50%)'}),
              stagger(200, [
                animate('.3s ease-out', style({opacity: 1, transform: 'translateY(0%)'}))
              ])
            ]),
          ]),
      ]),
      transition(':leave', [
          style({position: 'absolute', left: 0, right: 0, opacity: 1}),
          animate('.3s ease-in-out',
          style({opacity: 0})),
          group([
            query('.answer', [
              style({opacity: 1, transform: 'translateY(0%)'}),
              stagger(200, [
                animate('0.3s ease-out', style({opacity: 0, transform: 'translateY(50%)'}))
              ])
            ]),
          ]),
      ]),



/*
      transition('* => *', [ // each time the binding value changes
        query(':enter', [
          style({opacity: 0, transform: 'translateY(50%)'}),
          stagger(200, [
            animate('0.3s ease-out', style({opacity: 1, transform: 'translateY(0%)'}))
          ])
        ], {optional: true}),
        query(':leave', [
          style({opacity: 1, transform: 'translateY(0%)'}),
          stagger(200, [
            animate('0.3s ease-out', style({opacity: 0, transform: 'translateY(50%)'}))
          ])
        ], {optional: true})
      ]),
*/


    ]),
    containerSlideUp,
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({transform: 'scale(3)', opacity: 1}),
            animate('.25s ease-in-out',
              style({transform: 'scale(1)', opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({transform: 'scale(1)', opacity: 1}),
            animate('.25s ease-in-out',
              style({transform: 'scale(3)', opacity: 0}))
          ]
        )
      ]
    ),
    trigger(
      'slideLeftOut',
      [
        transition(
          ':leave',
          [
            style({transform: 'translateX(0%)', position: 'absolute'}),
            animate('.5s ease-in-out',
              style({transform: 'translateX(-100%)'}))
          ]
        )
      ]
    ),


    slideLeft,
    pageTransition,
  ],
})
export class QuestionComponent implements OnInit {

  @ViewChild(PerfectScrollbarComponent, {static: false}) componentRef?: PerfectScrollbarComponent;


  public countDown = 0;
  private countDownTimer;
  public question = null;
  public questionNum = 1;

  public loadingQuestion = false
  public loadingAnswer = false;

  public showInfo = false;
  public questionInfo = null;

  public boosters = [];

  public user = null;

  public showLeaderBoard = false;
  public leaderBoard = null;

  public slideIn = false;
  public currentAnswers = [];

  public answerDown = 60;
  public answerDownTimer;

  public pauseTime$;
  public pauseTime = false;

  public position = 1;
  public score = 0;

  public gameDone = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public trainingQuestion: any = false

  public verifyInProgress = false;

  public newPoints: any = false;

  public answerSelected = false;

  public imagePath = environment.api + '/assets/';




  public quiz: Quiz = null;


  constructor(private et: EverythingService, private http: HttpClient, private router: Router,
              private ss: SoundService, private route: ActivatedRoute, private questionService: QuestionService,
              private quizService: QuizService,public track: TrackService,
  ) {
  }

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams.training) {
      this.trainingQuestion = 1;
    }

    this.quizService.getQuiz().subscribe((quiz) => {
      this.quiz = quiz;
      this.loadQuestion();
    });

    this.user = this.et.getUser();



  }



  loadQuestion(onemoretime = false) {
    this.verifyInProgress = false;
    this.answerDown = this.quiz.settings.questionTimeLimit;

    const maxQuestionTime = this.quiz.settings.questionTimeLimit ? this.quiz.settings.questionTimeLimit : 30;

    this.question = null;

    const to = setTimeout(() => {
      this.loadingQuestion = true;
    }, 1000);

    const boosters = {oneMoreTime: onemoretime};

    this.questionService.getNextQuestion(null, boosters)
      .subscribe(
        (question: any) => {
          if (to) {
            clearTimeout(to);
          }

          if (onemoretime) {
            gtag('event', 'use_booster', {
              event_category: 'Booster',
              event_label: 'novo_vprasanje'
            });
          }

          let questionsStarted = new Date(question.question_started).getTime();
          if (!questionsStarted) {
            questionsStarted = new Date().getTime();
          }
          const now = new Date().getTime();
          let timediff = maxQuestionTime - Math.round((now - questionsStarted) / 1000);
          if (timediff > maxQuestionTime) {
            timediff = maxQuestionTime;
          }
          if (timediff < 1) {
            timediff = 1;
          }
          this.answerDown = timediff;
          this.loadingQuestion = false;
          this.countDown = this.quiz.settings.countDown;
          this.question = question.question;
          this.questionNum = question.question_step;
          this.boosters = question.boosters;
          this.question.answers = this.question.answers.map((answer) => {
            answer.selected = false;
            return answer;
          }).sort(() => {
            return .5 - Math.random();
          });
          this.startCountdown();


          if (this.quiz.settings.scoreBoard) {
            if (!this.leaderBoard) {
              this.http.get(`${environment.api}/leader-board/${this.questionNum - 1}`).subscribe((leaderBoard: any) => {
                this.leaderBoard = leaderBoard;
                let meBoard = leaderBoard.find((item) => {
                  return item.user_id === this.user.id && item.score === this.score;
                });
                if (!meBoard) {
                  meBoard = leaderBoard.find((item) => {
                    return item.user_id === this.user.id;
                  });
                }
                if (meBoard) {
                  this.position = meBoard.position;
                  if (!this.score) {
                    this.score = meBoard.score;
                  }
                }
              });
            }
          }

          gtag('event', 'page_view', {
            page_title: 'Vprašanje - ' + (this.trainingQuestion ? 'Trening - ' : '') + this.questionNum,
            page_path: '/' + (this.trainingQuestion ? 'vprasanje-trening/' : 'vprasanje/') + this.questionNum,
          });
        }, (error) => {
          this.loadingQuestion = false;
          console.log(error);
          if (error.error.error === 'max_games') {
            this.router.navigate(['/game-done']);
          } else {
            this.router.navigate(['/']);
          }
        });
  }

  selectAnswer(answer) {
    this.answerSelected = true;
    if (this.verifyInProgress) {
      return;
    }
    answer.selected = !answer.selected;
    if (!this.question.multiple) {
      this.verifyAnswers();
    }
  }

  useBooster(selectedBooster) {
    if (selectedBooster === 'onemoretime') {
      this.loadQuestion(true);
    } else {
      this.http.post(`${environment.api}/question/booster`, {
        booster: selectedBooster,
      }, {
        params: {
          training: this.trainingQuestion ? this.trainingQuestion : '',
        }
      }).subscribe((resp: any) => {
        if (resp.snail) {
          this.boosters.push('snail');
          this.pauseTime = true;
          setTimeout(() => {
            this.pauseTime = false;
          }, 10000);

          gtag('event', 'use_booster', {
            event_category: 'Booster',
            event_label: 'ustavi_cas'
          });
        }

        if (resp.leave) {
          this.boosters.push('heart');

          this.question.answers = this.question.answers.map((answer) => {
            if (resp.leave.indexOf(answer.id) === -1) {
              answer.hide = true;
            }
            return answer;
          });

          gtag('event', 'use_booster', {
            event_category: 'Booster',
            event_label: 'polovicka'
          });
        }
      });
    }
  }

  verifyAnswers(timeOut = false) {
    this.verifyInProgress = true;
    this.question.multiple = false;
    this.answerSelected = false;

    const to = setTimeout(() => {
      this.loadingAnswer = true;
    }, 1000);

    if (this.answerDownTimer) {
      clearInterval(this.answerDownTimer);
    }
    const answersBody = [];
    if (!timeOut) {
      this.question.answers.forEach((answer) => {
        if (answer.selected) {
          answersBody.push(answer.id);
        }
      });
    }
    this.http.post(`${environment.api}/question/answer`, {
      answers: answersBody,
    }, {
      params: {
        training: this.trainingQuestion ? this.question.id : '',
      }
    }).subscribe((response: any) => {
      if (to) {
        clearTimeout(to);
      }
      this.loadingAnswer = false;
      this.answerDown = 0;
      if (response.success) {
        //this.ss.playSuccess();
        this.question.answers.forEach((answer) => {
          if (answer.selected) {
            answer.success = true;
            answer.correct = true;
          }
        });
      } else {
        //this.ss.playError();
        this.question.answers.forEach((answer) => {
          if (answer.selected) {
            if (response.correct_answers.indexOf(answer.id) === -1) {
              answer.error = true;
              answer.wrong = true;
            } else {
              answer.success = true;
              answer.correct = true;
            }
          } else {
            if (response.correct_answers.indexOf(answer.id) !== -1) {
              answer.correct = true;
            }
          }
        });
      }

      this.newPoints = response.points;

      if (response.score) {
        this.score = response.score;
      }

      this.questionInfo = response.good_to_know;

      if (response.game_done) {
        this.gameDone = true;
      }

      const boardId = this.trainingQuestion ? 15 : this.questionNum;
      this.leaderBoard = null;
      if (this.quiz.settings.scoreBoard) {
        this.http.get(`${environment.api}/leader-board/${boardId}`).subscribe((leaderBoard: any) => {
          this.leaderBoard = leaderBoard;
          let meBoard = leaderBoard.find((item) => {
            return item.user_id === this.user.id && item.score === this.score;
          });
          if (!meBoard) {
            meBoard = leaderBoard.find((item) => {
              return item.user_id === this.user.id;
            });
          }
          if (meBoard) {
            this.position = meBoard.position;
          }
        });
      }
      timer(timeOut ? 1000 : 1500, 2500).pipe(
        take(1),
        takeUntil(this.destroy$)
      ).subscribe((x) => {
        switch (x) {
          case 0:
            this.newPoints = false;
            this.showInfo = true;
            gtag('event', 'page_view', {
              page_title: 'Vprašanje - ' + (this.trainingQuestion ? 'Trening - ' : '') + this.questionNum + ' - Info',
              page_path: '/' + (this.trainingQuestion ? 'vprasanje-trening/' : 'vprasanje/') + this.questionNum + '/info',
            });
            // this.ss.playReveal();
            break;
          case 1:
            this.showInfo = false;
            this.showLeaderBoard = true;
            break;
          default:
            this.destroy$.next(true);
            if (this.gameDone) {
              if (this.trainingQuestion) {
                this.router.navigate(['/question']);
              } else {
                this.router.navigate(['/user-finalize']);
              }
            } else {
              this.showInfo = false;
              this.showLeaderBoard = false;
              if (this.trainingQuestion) {
                this.trainingQuestion++;
              }
              this.loadQuestion();
            }
            break;
        }
      });
    }, (e) => {
      this.verifyInProgress = false;
      this.loadingAnswer = false;
    });
  }

  goToLeader() {

    this.destroy$.next(true);
    if (!this.checkQuestioner()) {
      if ([1, 4, 8, 12, 13, 14].indexOf(this.questionNum) === -1 || !this.quiz.settings.scoreBoard) {
        this.goToNext();
      } else {
        this.showInfo = false;
        this.showLeaderBoard = true;
        gtag('event', 'page_view', {
          page_title: 'Vprašanje - ' + (this.trainingQuestion ? 'Trening - ' : '') + this.questionNum + ' - Lestvica',
          page_path: '/' + (this.trainingQuestion ? 'vprasanje-trening/' : 'vprasanje/') + this.questionNum + '/lestvica',
        });
      }
    }
  }

  stopAdvanceTimer() {
    this.destroy$.next(true);
  }

  checkQuestioner() {
    if (this.user.questioner) {
      if (this.user.questioner[2] || this.user.questioner[3]) {
        return false;
      }
    }
    /*
    if ([4, 8].indexOf(this.questionNum) !== -1) {
      this.router.navigate(['/anketa']);
      return true;
    } else {
      return false;
    }
    */
  }

  goToNext() {
    this.stopAdvanceTimer();
    if (!this.checkQuestioner()) {
      if (this.gameDone) {
        if (this.trainingQuestion) {
          this.router.navigate(['/question']);
        } else {
          this.router.navigate(['/user-finalize']);
        }
      } else {
        this.showInfo = false;
        this.showLeaderBoard = false;
        if (this.trainingQuestion) {
          this.trainingQuestion++;
        }
        this.loadQuestion();
      }
    }
  }

  startAnswerDown() {
    this.answerDownTimer = setInterval(() => {
      if (this.pauseTime) {
        return;
      }
      this.answerDown--;
      if (this.answerDown === 0) {
        this.verifyAnswers(true);
        clearInterval(this.answerDown);
      }


      switch (this.answerDown) {
        case 20:
        case 10:
        case 3:
        case 2:
        case 1:
          //this.ss.playTimer();
          break;
      }
    }, 100);
  }


  startCountdown() {

    if (!this.quiz.settings.countDown) {
      this.startAnswerDown();
      return;

    }
    
    //this.ss.playTimer();
    this.countDownTimer = setInterval(() => {
      this.countDown--;
      if (this.countDown === 0) {
        setTimeout(() => {
          this.slideIn = true;
        }, 500);

        clearInterval(this.countDownTimer);
        this.startAnswerDown();

      } else {
        //this.ss.playTimer();

      }
    }, 1000);

  }




}
